function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  transition: background-color 150ms ease-in-out;\n\n  &:hover {\n    ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { alignItems, borderColor, borderRadius, borders, boxShadow, color, display, height, justifyContent, maxWidth, position, space, width, zIndex, textAlign } from 'styled-system';
import { cursor, hoverBg } from '../customProperties';
export var BorderBoxElement = styled.div(_templateObject(), alignItems, borderRadius, borders, borderColor, boxShadow, color, cursor, display, height, justifyContent, maxWidth, position, space, width, zIndex, textAlign, hoverBg);