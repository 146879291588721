function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  position: absolute;\n  top: 0;\n  left: 10px;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  appearance: none;\n  border: none;\n  box-sizing: border-box;\n  cursor: pointer;\n  outline-style: none;\n  text-align: center;\n  text-decoration: none;\n  user-select: none;\n  white-space: nowrap;\n  transition: all 400ms cubic-bezier(", ");\n\n  &:hover {\n    ", ";\n    ", ";\n    ", ";\n    ", ";\n    transform: translateY(-2px);\n  }\n\n  &:active {\n    transform: translateY(0);\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import posed from 'react-pose';
import { easings } from '@marvelapp/theme';
import { alignItems, borderRadius, boxShadow, color, display, fontSize, fontWeight, height, justifyContent, lineHeight, minWidth, position, space, width } from 'styled-system';
import { fill, hoverBg, hoverBoxShadow, hoverColor, hoverFill, pointerEvents } from '../customProperties';
export var ButtonElement = styled.button(_templateObject(), alignItems, borderRadius, boxShadow, color, display, fill, fontSize, fontWeight, height, justifyContent, lineHeight, minWidth, pointerEvents, position, space, width, easings.smooth.toString(), hoverBg, hoverBoxShadow, hoverColor, hoverFill);
export var LoaderWrap = styled.div(_templateObject2(), width, height);
var iconTransition = {
  enter: {
    opacity: 1,
    scale: 1,
    delay: 150,
    transition: {
      duration: 700,
      ease: easings.smooth
    }
  },
  exit: {
    opacity: 0,
    scale: 0,
    transition: {
      duration: 600,
      ease: easings.smooth
    }
  }
};
export var IconContainer = styled(posed.div(iconTransition))(_templateObject3());