// TODO: Unify input, button and select sizes
export var buttonSizes = [{
  fontSize: 1,
  borderRadius: 12,
  height: 24,
  lineHeight: '24px',
  pl: 1,
  pr: 1
}, {
  fontSize: 2,
  borderRadius: 15,
  height: 30,
  lineHeight: '30px',
  pl: 2,
  pr: 2
}, {
  fontSize: [1, 2, 2, 2],
  borderRadius: [15, 20, 20, 20],
  height: [30, 40, 40, 40],
  lineHeight: ['30px', '40px', '40px', '40px'],
  pl: [2, 25, 25, 25],
  pr: [2, 25, 25, 25]
}, {
  fontSize: 3,
  borderRadius: 25,
  height: 50,
  lineHeight: '50px',
  pl: 30,
  pr: 30
}, {
  fontSize: 4,
  borderRadius: 30,
  height: 60,
  lineHeight: '60px',
  pl: 30,
  pr: 30
}];
export var loaderSizes = [{
  size: 12,
  borderWidth: 2
}, {
  size: 16,
  borderWidth: 2
}, {
  size: 20,
  borderWidth: 4
}, {
  size: 24,
  borderWidth: 4
}];
export var iconSizes = [{
  width: 18,
  height: 18
}, {
  width: 24,
  height: 24
}, {
  width: 32,
  height: 32
}, {
  width: 40,
  height: 40
}];