import React from 'react';

import { MasonryElement } from './styles';

function Masonry({ children, ...props }) {
  return <MasonryElement {...props}>{children}</MasonryElement>;
}

Masonry.defaultProps = {
  m: '0 auto',
  maxWidth: 1280,
};

export default Masonry;
