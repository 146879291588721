function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PoseGroup } from 'react-pose';
import theme, { CHECKBOX_TICK_ICON } from '@marvelapp/theme';
import Loader from '../Loader';
import Icon from '../Icon';
import { ButtonElement, IconContainer } from './styles';
import { buttonSizes, loaderSizes, iconSizes } from './sizes';
import kinds from './kinds';

function Button(_ref) {
  var children = _ref.children,
      hasSucceeded = _ref.hasSucceeded,
      isLoading = _ref.isLoading,
      kind = _ref.kind,
      size = _ref.size,
      tag = _ref.tag,
      props = _objectWithoutProperties(_ref, ["children", "hasSucceeded", "isLoading", "kind", "size", "tag"]);

  var loadingProps = isLoading && _objectSpread({}, kinds.ghost, {
    bg: 'snow',
    color: 'snow',
    disabled: true,
    fill: 'snow',
    pointerEvents: 'none',
    position: 'relative'
  });

  var succeededProps = hasSucceeded && {
    bg: 'green',
    color: 'green',
    disabled: true,
    fill: 'green',
    pointerEvents: 'none',
    position: 'relative'
  };
  /* PoseGroup animates all children in based on components with transitions
   * created in styles.js. Each one needs a unique key prop! */

  var icons = React.createElement(Fragment, null, React.createElement(PoseGroup, null, isLoading && React.createElement(IconContainer, {
    key: "loader"
  }, React.createElement(Loader, loaderSizes[size]))), React.createElement(PoseGroup, null, hasSucceeded && React.createElement(IconContainer, {
    key: "tick"
  }, React.createElement(Icon, _extends({
    color: "white",
    paths: CHECKBOX_TICK_ICON
  }, iconSizes[size])))));
  /* Order is important here because the last two objects contain stateful
   * props which should overwrite all the rest */

  var allProps = _objectSpread({}, buttonSizes[size], kinds[kind], props, succeededProps, loadingProps);

  if (tag) {
    var WithTag = ButtonElement.withComponent(tag);
    return React.createElement(WithTag, allProps, icons, children);
  }

  return React.createElement(ButtonElement, allProps, icons, children);
}

Button.propTypes = {
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  boxShadow: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fill: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  hoverBg: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  hoverBoxShadow: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  hoverColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  hoverFill: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),

  /** margin */
  m: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),

  /** margin-top */
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),

  /** margin-right */
  mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),

  /** margin-bottom */
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),

  /** margin-left */
  ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),

  /** margin-left and margin-right */
  mx: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),

  /** margin-top and margin-bottom */
  my: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),

  /** padding */
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),

  /** padding-top */
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),

  /** padding-right */
  pr: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),

  /** padding-bottom */
  pb: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),

  /** padding-left */
  pl: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),

  /** padding-left and padding-right */
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),

  /** padding-top and padding-bottom */
  py: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  hasSucceeded: PropTypes.bool,
  isLoading: PropTypes.bool
};
Button.defaultProps = {
  display: 'inline-block',
  fontWeight: '500',
  hasSucceeded: false,
  isLoading: false,
  lineHeight: 1,
  size: 1,
  theme: theme
};
export default Button;