import { DASHBOARD_SPACE, PROJECT_WIDTH, SIDEBAR_WIDTH } from './constants'; // Dashboard container widths

export var containerWidths = ["".concat(PROJECT_WIDTH, "px"), "".concat(PROJECT_WIDTH * 2, "px"), "".concat(PROJECT_WIDTH * 3, "px"), "".concat(PROJECT_WIDTH * 4, "px")]; // Breakpoints

export var breakpoints = ["".concat(PROJECT_WIDTH + SIDEBAR_WIDTH + DASHBOARD_SPACE, "px"), "".concat(PROJECT_WIDTH * 2 + SIDEBAR_WIDTH + DASHBOARD_SPACE, "px"), "".concat(PROJECT_WIDTH * 3 + SIDEBAR_WIDTH + DASHBOARD_SPACE, "px"), "".concat(PROJECT_WIDTH * 4 + SIDEBAR_WIDTH + DASHBOARD_SPACE, "px")]; // Color scheme

export var colors = {
  marvelDark: '#009EEB',
  marvel: '#1FB6FF',
  marvelLight: '#89DCFF',
  marvelExtraLight: '#B7EAFB',
  greenDark: '#44C553',
  green: '#60D956',
  greenLight: '#87DC72',
  greenExtraLight: '#ADEA9E',
  orange: '#FD6F3C',
  orangeLight: '#FF977A',
  orangeExtraLight: '#FFB6A1',
  orangeExtraExtraLight: '#FFCEBD',
  yellow: '#FFAE30',
  yellowLight: '#FFC772',
  yellowExtraLight: '#FFD79B',
  yellowExtraExtraLight: '#FFE3B7',
  purple: '#AD6EDD',
  purpleLight: '#C896EA',
  purpleExtraLight: '#D6AFF1',
  purpleExtraExtraLight: '#DFC7F0',
  teal: '#60D2C9',
  tealLight: '#8FDFDA',
  tealExtraLight: '#BBEDED',
  tealExtraExtraLight: '#DDF6F6',
  licorice: '#0C1014',
  black: '#222D39',
  steel: '#2A3440',
  slate: '#3E4854',
  silver: '#8792A1',
  smokeExtraDark: '#AAB5C5',
  smokeDark: '#C3CCD7',
  smoke: '#D5DCE3',
  snowExtraDark: '#E6E9EF',
  snowDark: '#F0F2F6',
  snow: '#F9FAFB',
  white: '#FFFFFF',
  red: '#F95849',
  twitter: '#55acee',
  facebook: '#3b5999',
  dribbble: '#ea4c89',
  dropbox: '#007ee5',
  google: '#dc4e41',
  instagram: '#3f729b',
  linkedin: '#0077b5',
  pocket: '#ef4056'
}; // Font families

export var fonts = ['"Graphik", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"', '"SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace']; // Radii, spacing scale and typography scale (in px)

export var radii = [3, 4, 6, 8, 10];
export var space = [5, 10, 15, 20, 40, 80];
export var fontSizes = [10, 11, 13, 15, 17, 19, 25, 36, 46, 60]; // Custom easing functions

export var easings = {
  smooth: [0.23, 1, 0.32, 1],
  bouncy: [0.68, -0.55, 0.265, 1.55]
}; // Theme

var theme = {
  breakpoints: breakpoints,
  colors: colors,
  fonts: fonts,
  fontSizes: fontSizes,
  radii: radii,
  space: space
};
export * from './constants';
export * from './icons';
export default theme;