import React, { Component, Fragment } from 'react';
import { Provider, Client, Connect, query } from 'urql';
import gql from 'fake-tag';
import { sortBy, cloneDeep } from 'lodash-es';

import { GlobalStyle } from '../../theme/globalStyles';
import Board from '../../components/Board';

const authToken = '1Mg210kVbR4xGIVtH59knm014qE8WM';

const client = new Client({
  url: 'https://marvelapp.com/graphql',
  fetchOptions: {
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${authToken}`,
    },
  },
});

const getProjectQuery = gql`
  query {
    user {
      username
      email
      projects(first: 1) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            pk
            name
            prototypeUrl
            sections {
              edges {
                node {
                  pk
                  name
                  colour
                  createdAt
                  modifiedAt
                  screens(first: 50) {
                    edges {
                      node {
                        externalId
                        name
                        sourcePlatform
                        sectionPk
                        createdAt
                        modifiedAt
                        content {
                          ... on ImageScreen {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

function getSection(sections, pk) {
  for (var i = 0; i < sections.length; i++) {
    const section = sections[i];
    if (section.pk === pk) {
      return section;
    }
  }
}

function getSortedSections(sectionEdges) {
  let sections = [];
  let allScreens = [];
  for (var i = 0; i < sectionEdges.length; i++) {
    let section = sectionEdges[i].node;
    let screens = [];
    for (var j = 0; j < section.screens.edges.length; j++) {
      let screen = section.screens.edges[j].node;
      screens.unshift(screen);
      allScreens.unshift(screen);
    }
    screens = sortBy(screens, 'modifiedAt').reverse();
    section.screens = screens;
    sections.unshift(section);
  }
  allScreens = sortBy(allScreens, 'modifiedAt').reverse();
  let alreadySorted = {};
  let sortedSections = [];
  for (var k = 0; k < allScreens.length; k++) {
    const screenThing = allScreens[k];
    if (alreadySorted[screenThing.sectionPk] == null) {
      sortedSections.push(getSection(sections, screenThing.sectionPk));
      alreadySorted[screenThing.sectionPk] = screenThing.sectionPk;
    }
  }
  return sortedSections;
}

/* eslint-disable react/prefer-stateless-function */
class App extends Component {
  constructor(props) {
    super(props);
    this.pollerStarted = false;
    this.state = {
      poller: 0,
    };
  }

  pollSection(refetch) {
    if (!this.pollerStarted) {
      setTimeout(() => {
        console.log('Calling refresh');
        refetch({ skipCache: true });
        this.pollerStarted = false;
      }, 5000);
      this.pollerStarted = true;
    }
  }

  render() {
    return (
      <Provider client={client}>
        <Connect query={query(getProjectQuery)} cache={false}>
          {({ loaded, data, refetch, cache }) => {
            if (!loaded) {
              return <div />;
            }
            const newData = cloneDeep(data);
            const project = newData.user.projects.edges[0].node;
            const sectionEdges = project.sections.edges.slice();
            const sections = getSortedSections(sectionEdges);
            if (!this.pollerStarted) {
              this.pollSection(refetch);
            }
            return (
              <Fragment>
                <GlobalStyle color="slate" fontFamily={0} />
                <Board sections={sections} />
              </Fragment>
            );
          }}
        </Connect>
      </Provider>
    );
  }
}

export default App;
