function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

import React from 'react';
import { CLOSE_ICON } from '@marvelapp/theme';
import Button from '../Button';
import Icon from '../Icon';

function CloseButton(_ref) {
  var props = _extends({}, _ref);

  return React.createElement(Button, _extends({
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    px: "0px"
  }, props), React.createElement(Icon, {
    paths: CLOSE_ICON,
    display: "block"
  }));
}

CloseButton.defaultProps = {
  borderRadius: 20,
  color: 'silver',
  height: 40,
  kind: 'ghost',
  width: 40
};
export default CloseButton;