function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n      overflow: hidden;\n      text-overflow: ellipsis;\n      white-space: nowrap;\n    "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled, { css } from 'styled-components';
import { borderRadius, color, display, fontSize, fontWeight, letterSpacing, lineHeight, maxWidth, space, textAlign } from 'styled-system';
import { textTransform, whiteSpace } from '../customProperties';
export var TextElement = styled.p(_templateObject(), borderRadius, color, display, fontSize, fontWeight, letterSpacing, lineHeight, maxWidth, space, textAlign, textTransform, whiteSpace, function (props) {
  return props.truncated && css(_templateObject2());
});