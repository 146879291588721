function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  animation: ", " 500ms linear infinite;\n  border-radius: 50%;\n  border-style: solid;\n  border-bottom-color: transparent;\n  border-right-color: transparent;\n  border-top-color: transparent;\n\n  position: absolute;\n  top: 0;\n  left: 0;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  border-radius: 50%;\n  border-style: solid;\n\n  opacity: 0.3;\n  position: relative;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled, { keyframes } from 'styled-components';
import { borderColor, color, width, height } from 'styled-system';
import { borderWidth } from '../customProperties';
var Static = styled.div(_templateObject(), borderColor, borderWidth, color, height, width);
var spinAnimation = keyframes(_templateObject2());
var Spinny = styled.div(_templateObject3(), borderColor, borderWidth, color, height, width, spinAnimation);
export { Static, Spinny };