import React from 'react';
import { Flex } from '@rebass/grid';
import { Text, Logo as MarvelLogo } from '@marvelapp/ui';

function Logo({ ...props }) {
  return (
    <Flex alignItems="center" {...props}>
      <MarvelLogo color="marvel" mr={1} />
      <Text fontSize={3} mr={1} color="smokeDark" mb={1}>
        |
      </Text>
      <Text
        color="silver"
        fontSize={1}
        fontWeight={500}
        letterSpacing={2}
        mb={1}
        textTransform="uppercase"
      >
        Ideate
      </Text>
    </Flex>
  );
}

export default Logo;
