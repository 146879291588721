export var ADD_TO_FOLDER_ICON = ['M19,21H5a3,3,0,0,1-3-3V6A3,3,0,0,1,5,3h5a1,1,0,0,1,.83.45L12.54,6H19a3,3,0,0,1,3,3v9A3,3,0,0,1,19,21ZM5,5A1,1,0,0,0,4,6V18a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V9a1,1,0,0,0-1-1H12a1,1,0,0,1-.83-.45L9.46,5Z', 'M12,17a1,1,0,0,1-1-1V10a1,1,0,0,1,2,0v6A1,1,0,0,1,12,17Z', 'M15,14H9a1,1,0,0,1,0-2h6a1,1,0,0,1,0,2Z'];
export var ARCHIVE_ICON = ['M20,21H4a1,1,0,0,1-1-1V9A1,1,0,0,1,5,9V19H19V9a1,1,0,0,1,2,0V20A1,1,0,0,1,20,21Z', 'M22,10H2A1,1,0,0,1,1,9V4A1,1,0,0,1,2,3H22a1,1,0,0,1,1,1V9A1,1,0,0,1,22,10ZM3,8H21V5H3Z', 'M14,14H10a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z'];
export var ARROW_DOWN_ICON = ['M12,19a1,1,0,0,1-1-1V6a1,1,0,0,1,2,0V18A1,1,0,0,1,12,19Z', 'M12,19a1,1,0,0,1-.71-.29l-4-4a1,1,0,1,1,1.42-1.42l4,4a1,1,0,0,1,0,1.42A1,1,0,0,1,12,19Z', 'M12,19a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l4-4a1,1,0,0,1,1.42,1.42l-4,4A1,1,0,0,1,12,19Z'];
export var ARROW_LEFT_ICON = ['M17.69,13H6a1,1,0,0,1,0-2H17.69a1,1,0,0,1,0,2Z', 'M6,13a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l4-4a1,1,0,1,1,1.42,1.42l-4,4A1,1,0,0,1,6,13Z', 'M10,17a1,1,0,0,1-.71-.29l-4-4a1,1,0,0,1,1.42-1.42l4,4a1,1,0,0,1,0,1.42A1,1,0,0,1,10,17Z'];
export var ARROW_RIGHT_ICON = ['M18,13H6a1,1,0,0,1,0-2H18a1,1,0,0,1,0,2Z', 'M18,13a1,1,0,0,1-.71-.29l-4-4a1,1,0,1,1,1.42-1.42l4,4a1,1,0,0,1,0,1.42A1,1,0,0,1,18,13Z', 'M14,17a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l4-4a1,1,0,0,1,1.42,1.42l-4,4A1,1,0,0,1,14,17Z'];
export var ARROW_UP_ICON = ['M16.71,9.29l-4-4a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-4,4a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L11,8.41V18a1,1,0,0,0,2,0V8.41l2.29,2.3a1,1,0,0,0,1.42,0A1,1,0,0,0,16.71,9.29Z'];
export var COPY_ICON = ['M18,21H12a3,3,0,0,1-3-3V12a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3v6A3,3,0,0,1,18,21ZM12,11a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V12a1,1,0,0,0-1-1Z', 'M6.31,15H5.54A2.54,2.54,0,0,1,3,12.46V5.54A2.54,2.54,0,0,1,5.54,3h6.92A2.54,2.54,0,0,1,15,5.54v.77a1,1,0,0,1-2,0V5.54A.54.54,0,0,0,12.46,5H5.54A.54.54,0,0,0,5,5.54v6.92a.54.54,0,0,0,.54.54h.77a1,1,0,0,1,0,2Z'];
export var DELETE_ICON = ['M20,6H16V5a2.27,2.27,0,0,0-2.5-2h-3A2.27,2.27,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V18.25A2.73,2.73,0,0,0,7.71,21h8.58A2.73,2.73,0,0,0,19,18.25h0V8h1a1,1,0,0,0,0-2ZM10,5.12A1.08,1.08,0,0,1,10.5,5h3a1.08,1.08,0,0,1,.5.12V6H10Zm7,13.13a.73.73,0,0,1-.71.75H7.71A.73.73,0,0,1,7,18.25H7V8H17Z', 'M15,17H13V10h2Z', 'M11,17H9V10h2Z'];
export var CHECKBOX_TICK_ICON = ['M10,17a1.05,1.05,0,0,1-.71-.29l-3-3a1,1,0,0,1,1.42-1.42L10,14.54l6.3-7.2a1,1,0,0,1,1.5,1.32l-7,8A1,1,0,0,1,10,17Z'];
export var SETTINGS_ICON = ['M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z', 'M19,14.85a.38.38,0,0,1,.39-.21,2.64,2.64,0,0,0,0-5.28h-.13A.34.34,0,0,1,19,9.23a.47.47,0,0,0-.06-.15A.38.38,0,0,1,19,8.65,2.64,2.64,0,1,0,15.24,5a.35.35,0,0,1-.39.07.38.38,0,0,1-.21-.39,2.64,2.64,0,0,0-5.28,0v.13A.34.34,0,0,1,9.23,5l-.15.06A.38.38,0,0,1,8.65,5,2.71,2.71,0,0,0,4.92,5a2.62,2.62,0,0,0-.77,1.87A2.67,2.67,0,0,0,5,8.76.38.38,0,0,1,5,9.2a.36.36,0,0,1-.37.23,2.64,2.64,0,1,0,0,5.27h.13a.37.37,0,0,1,.33.22.39.39,0,0,1-.11.43,2.63,2.63,0,0,0-.78,1.87A2.65,2.65,0,0,0,5,19.08a2.68,2.68,0,0,0,3.78,0A.38.38,0,0,1,9.2,19a.36.36,0,0,1,.23.37,2.64,2.64,0,1,0,5.27,0v-.13a.37.37,0,0,1,.22-.33.38.38,0,0,1,.43.12,2.69,2.69,0,0,0,3.73,0,2.59,2.59,0,0,0,.77-1.86A2.67,2.67,0,0,0,19,15.24a.34.34,0,0,1-.07-.38Zm-1.83-.8a2.37,2.37,0,0,0,.53,2.65.64.64,0,1,1-1,.84,2.36,2.36,0,0,0-4,1.68v.14a.64.64,0,0,1-.63.64.66.66,0,0,1-.64-.73A2.31,2.31,0,0,0,10,17.14a2.37,2.37,0,0,0-.94-.2,2.41,2.41,0,0,0-1.71.73.67.67,0,0,1-.9,0,.64.64,0,0,1-.19-.45.7.7,0,0,1,.25-.51,2.36,2.36,0,0,0-1.68-4H4.64A.64.64,0,0,1,4,12.07a.66.66,0,0,1,.73-.64A2.32,2.32,0,0,0,6.86,10,2.36,2.36,0,0,0,6.33,7.3a.65.65,0,0,1,0-.9.63.63,0,0,1,1,.06A2.36,2.36,0,0,0,9.7,7a1.09,1.09,0,0,0,.24-.07,2.34,2.34,0,0,0,1.42-2.15V4.64A.64.64,0,0,1,12,4a.66.66,0,0,1,.64.71,2.33,2.33,0,0,0,1.41,2.15,2.36,2.36,0,0,0,2.65-.53.64.64,0,0,1,1.09.45.69.69,0,0,1-.25.51A2.36,2.36,0,0,0,17,9.7a1.09,1.09,0,0,0,.07.24,2.34,2.34,0,0,0,2.15,1.42h.14A.64.64,0,0,1,20,12a.66.66,0,0,1-.71.64A2.35,2.35,0,0,0,17.14,14.05Z'];
export var PLAY_ICON = ['M17.62,11.3A.85.85,0,0,1,18,12a.92.92,0,0,1-.38.72,42.16,42.16,0,0,1-9.86,5.2,1.26,1.26,0,0,1-.43.08,1.22,1.22,0,0,1-.56-.14.87.87,0,0,1-.51-.71C6.24,17,6,14.61,6,12s.24-5,.26-5.13a.91.91,0,0,1,.51-.73A1.16,1.16,0,0,1,7.33,6a1.61,1.61,0,0,1,.43.07A41.24,41.24,0,0,1,17.62,11.3Z'];
export var CLOSE_ICON = ['M13.41,12l3.3-3.29a1,1,0,1,0-1.42-1.42L12,10.59,8.71,7.29A1,1,0,1,0,7.29,8.71L10.59,12l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l3.29,3.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z'];
export var IMAGE_ICON = ['M18,3H6A3,3,0,0,0,3,6V18a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V6A3,3,0,0,0,18,3ZM5,6A1,1,0,0,1,6,5H18a1,1,0,0,1,1,1v5.59l-2.29-2.3a1,1,0,0,0-1.42,0L5.66,18.93A1,1,0,0,1,5,18ZM18,19H8.41L16,11.41l3,3V18A1,1,0,0,1,18,19Z', 'M8.5,11A2.5,2.5,0,1,0,6,8.5,2.5,2.5,0,0,0,8.5,11Zm0-3a.5.5,0,1,1-.5.5A.5.5,0,0,1,8.5,8Z'];
export var TRIANGLE_UP_ICON = ['M16.71,13.29l-4-4a1,1,0,0,0-1.42,0l-4,4a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,11.41l3.29,3.3a1,1,0,0,0,1.42,0A1,1,0,0,0,16.71,13.29Z'];
export var TRIANGLE_RIGHT_ICON = ['M15.71,11.29l-4-4a1,1,0,1,0-1.42,1.42L13.59,12l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4A1,1,0,0,0,15.71,11.29Z'];
export var TRIANGLE_DOWN_ICON = ['M16.71,9.29a1,1,0,0,0-1.42,0L12,12.59,8.71,9.29a1,1,0,1,0-1.42,1.42l4,4a1,1,0,0,0,1.42,0l4-4A1,1,0,0,0,16.71,9.29Z'];
export var TRIANGLE_LEFT_ICON = ['M10.41,12l3.3-3.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0,0,1.42l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z'];