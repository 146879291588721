function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { Svg } from './styles';

function Icon(_ref) {
  var paths = _ref.paths,
      props = _objectWithoutProperties(_ref, ["paths"]);

  return React.createElement(Svg, props, paths.map(function (path) {
    return React.createElement("path", {
      d: path,
      key: path
    });
  }));
}

Icon.defaultProps = {
  fill: 'currentColor',
  height: 24,
  viewBox: '0 0 24 24',
  width: 24
};
export default Icon;