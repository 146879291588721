import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from '@marvelapp/theme';

import 'minireset.css/minireset.css';

import App from './components/App';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <Route component={App} />
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
