import styled from 'styled-components';
import posed from 'react-pose';
import { space, maxWidth } from 'styled-system';
import { easings } from '@marvelapp/theme';

const containerTransition = {
  open: {
    staggerChildren: 120,
  },
};

export const ContainerElement = styled(posed.div(containerTransition))`
  ${space};
  ${maxWidth};
`;

export const Item = posed.div({
  open: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1000,
      ease: easings.smooth,
    },
  },
  closed: {
    y: 40,
    opacity: 0,
    transition: {
      duration: 1000,
      ease: easings.smooth,
    },
  },
});
