import { colors } from '@marvelapp/theme';
import { hexToRGB } from '../utils';
var white05 = hexToRGB(colors.white, 0.5);
var kinds = {
  ghost: {
    bg: 'transparent',
    color: 'slate',
    fill: 'slate',
    hoverBg: 'marvel',
    hoverColor: 'white',
    hoverFill: 'white',
    boxShadow: "inset 0 0 0 1px ".concat(colors.smoke),
    hoverBoxShadow: 'none'
  },
  ghostReversed: {
    bg: 'transparent',
    boxShadow: "inset 0 0 0 1px ".concat(white05),
    color: 'white',
    fill: 'white',
    hoverBg: 'white',
    hoverBoxShadow: 'none',
    hoverColor: 'slate',
    hoverFill: 'slate'
  },
  disabled: {
    bg: 'snow',
    boxShadow: "inset 0 0 0 1px ".concat(colors.smoke),
    color: 'silver',
    fill: 'silver',
    pointerEvents: 'none'
  },
  green: {
    bg: 'green',
    color: 'white',
    fill: 'white',
    hoverBg: 'greenDark'
  }
};
export default kinds;