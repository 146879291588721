import React from 'react';
import { Route } from 'react-router-dom';

import { GlobalStyle } from '../../theme/globalStyles';

import Home from '../../pages/Home';
import Product from '../../pages/Product';

export default function App() {
  return (
    <div>
      <GlobalStyle color="slate" fontFamily={0} />
      <Route path="/" component={Product} exact />
      <Route path="/home" component={Home} exact />
    </div>
  );
}
