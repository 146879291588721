import { style } from 'styled-system';
export var borderTopLeftRadius = style({
  prop: 'borderTopLeftRadius',
  cssProperty: 'border-top-left-radius',
  key: 'radii'
});
export var borderTopRightRadius = style({
  prop: 'borderTopRightRadius',
  cssProperty: 'border-top-right-radius',
  key: 'radii'
});
export var borderBottomRightRadius = style({
  prop: 'borderBottomRightRadius',
  cssProperty: 'border-bottom-right-radius',
  key: 'radii'
});
export var borderBottomLeftRadius = style({
  prop: 'borderBottomLeftRadius',
  cssProperty: 'border-bottom-left-radius',
  key: 'radii'
});
export var borderWidth = style({
  prop: 'borderWidth',
  cssProperty: 'border-width'
});
export var cursor = style({
  prop: 'cursor',
  cssProperty: 'cursor'
});
export var fill = style({
  prop: 'fill',
  cssProperty: 'fill',
  key: 'colors'
});
export var hoverColor = style({
  prop: 'hoverColor',
  cssProperty: 'color',
  key: 'colors'
});
export var hoverBg = style({
  prop: 'hoverBg',
  cssProperty: 'background-color',
  key: 'colors'
});
export var hoverFill = style({
  prop: 'hoverFill',
  cssProperty: 'fill',
  key: 'colors'
});
export var hoverBoxShadow = style({
  prop: 'hoverBoxShadow',
  cssProperty: 'box-shadow',
  key: 'shadows'
});
export var focusColor = style({
  prop: 'focusColor',
  cssProperty: 'color',
  key: 'colors'
});
export var focusBg = style({
  prop: 'focusBg',
  cssProperty: 'background-color',
  key: 'colors'
});
export var focusBoxShadow = style({
  prop: 'focusBoxShadow',
  cssProperty: 'box-shadow',
  key: 'shadows'
});
export var objectFit = style({
  prop: 'objectFit',
  cssProperty: 'object-fit'
});
export var objectPosition = style({
  prop: 'objectPosition',
  cssProperty: 'object-position'
});
export var transform = style({
  prop: 'transform',
  cssProperty: 'transform'
});
export var textTransform = style({
  prop: 'textTransform',
  cssProperty: 'text-transform'
});
export var pointerEvents = style({
  prop: 'pointerEvents',
  cssProperty: 'pointer-events'
});
export var whiteSpace = style({
  prop: 'whiteSpace',
  cssProperty: 'white-space'
});