import styled from 'styled-components';
import posed from 'react-pose';
import { borderRadius, color } from 'styled-system';
import { easings } from '@marvelapp/theme';

export const HoverItem = styled.div`
  ${borderRadius};
  ${color};
  cursor: pointer;
  overflow: hidden;

  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transition: all 400ms cubic-bezier(${easings.smooth.toString()});

  @media only screen and (min-width: 768px) {
    &:hover {
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
      transform: translateY(-5px) scale(1.02);
    }

    &:active {
      transform: none;
    }
  }
`;

const overlayTransition = {
  enter: {
    opacity: 1,
    transition: {
      duration: 400,
      ease: easings.smooth,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 600,
      ease: easings.smooth,
    },
  },
};

export const Overlay = styled(posed.div(overlayTransition))`
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  left: 0;
  position: fixed !important;
  right: 0;
  top: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const contentTransition = {
  enter: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 400,
      ease: easings.smooth,
    },
  },
  exit: {
    opacity: 0,
    y: 100,
    scale: 0.9,
    transition: {
      duration: 600,
      ease: easings.smooth,
    },
  },
};

export const Content = styled(posed.div(contentTransition))`
  bottom: 0;
  left: 0;
  position: fixed !important;
  right: 0;
  top: 0;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const buttonTransition = {
  enter: {
    opacity: 1,
    delay: 400,
    transition: {
      duration: 400,
      ease: easings.smooth,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 600,
      ease: easings.smooth,
    },
  },
};

export const ButtonTransiton = styled(posed.div(buttonTransition))`
  position: fixed !important;
  top: 0;
  right: 0;
  padding: 40px;
  z-index: 3;
`;
