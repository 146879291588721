import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BorderBox, Text } from '@marvelapp/ui';

import Container from '../Container';
import ImageItem from '../ImageItem';
import Logo from '../Logo';
import Masonry from '../Masonry';
import MasonryItem from '../MasonryItem';

import { Item } from '../Container/styles';

class Board extends Component {
  state = {
    hasLoaded: false,
  };

  componentDidMount() {
    this.setLoadedState();
  }

  setLoadedState = () => {
    this.setState({ hasLoaded: true });
  };

  render() {
    const { sections } = this.props;
    const { hasLoaded } = this.state;
    return (
      <Container
        maxWidth={1340}
        mx="auto"
        pose={hasLoaded ? 'open' : 'closed'}
        px={4}
        pb={4}
      >
        <Item>
          <Link to="/home">
            <Logo mt={4} />
          </Link>
        </Item>
        {sections.map(section => (
          <Fragment key={section.name}>
            <Item>
              <BorderBox mt={5} mb={4}>
                <Text color="licorice" fontWeight={600} fontSize={6}>
                  {section.name}
                </Text>
              </BorderBox>
            </Item>
            <Masonry>
              {section.screens.map(screen => (
                <MasonryItem key={screen.content.url}>
                  <Item>
                    <ImageItem src={screen.content.url} />
                  </Item>
                </MasonryItem>
              ))}
            </Masonry>
          </Fragment>
        ))}
      </Container>
    );
  }
}

Board.propTypes = {
  sections: PropTypes.array,
};

export default Board;
