/**
 *
 * Loader
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import Position from '../Position';
import { Spinny, Static } from './styles';

function Loader(_ref) {
  var size = _ref.size,
      color = _ref.color,
      borderWidth = _ref.borderWidth;
  return React.createElement(Position, {
    position: "relative",
    display: "block"
  }, React.createElement(Static, {
    borderColor: color,
    borderWidth: borderWidth,
    width: size,
    height: size
  }), React.createElement(Spinny, {
    borderColor: color,
    borderWidth: borderWidth,
    width: size,
    height: size
  }));
}

Loader.propTypes = {
  borderWidth: PropTypes.any,
  color: PropTypes.any,
  size: PropTypes.any
};
Loader.defaultProps = {
  borderWidth: 4,
  color: 'silver',
  size: 20
};
export default Loader;