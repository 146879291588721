import React from 'react';

import { MasonryItemElement } from './styles';

function MasonryItem({ children, ...props }) {
  return <MasonryItemElement {...props}>{children}</MasonryItemElement>;
}

MasonryItem.defaultProps = {
  maxWidth: 1280,
  mb: 20,
  ml: 'auto',
  mr: 'auto',
};

export default MasonryItem;
