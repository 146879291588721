function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  transition: opacity 300ms ease-in-out;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { borderColor, borderRadius, borders, boxShadow, display, height, maxHeight, maxWidth, opacity, position, space, width } from 'styled-system';
import { objectFit, objectPosition } from '../customProperties';
export var ImageElement = styled.img(_templateObject(), borderRadius, borders, borderColor, boxShadow, display, height, maxHeight, maxWidth, objectFit, objectPosition, opacity, position, space, width);