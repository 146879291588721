import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box } from '@rebass/grid';
import { Button, BorderBox, Image, Text } from '@marvelapp/ui';

import Logo from '../../components/Logo';

import mainImage from './mainImage.jpg';
import phoneOne from './phoneOne.jpg';

export default function Home() {
  return (
    <Fragment>
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <Box my={5}>
          <Logo />
        </Box>
        <BorderBox maxWidth={720} textAlign="center">
          <Image src={mainImage} srcSet={mainImage} maxWidth={720} mb={50} />
          <Text
            fontSize={8}
            pb={2}
            fontWeight={600}
            color="licorice"
            lineHeight={1.3}
          >
            Inspiration from your favourite platforms, in one place
          </Text>
          <Text fontSize={6}>
            Seamlessly collect, organise and share images from your favourite
            tools like Dribbble, inspectstagram and Behance
          </Text>
          <Link to="/">
            <Button bg="marvel" color="white" size={4} mt={4}>
              Get started
            </Button>
          </Link>
        </BorderBox>
      </Flex>

      <BorderBox maxWidth={900} m="0 auto">
        <Flex justifyContent="space-around" alignItems="center" mt={5}>
          <Image
            alt="Get projects off the ground quickly"
            maxWidth={400}
            src={phoneOne}
            srcSet={phoneOne}
            mr={4}
          />
          <Box>
            <Text fontSize={6} fontWeight={600} mb={1} color="licorice">
              Get projects off the ground quickly
            </Text>
            <Text fontSize={5}>
              An easy way to bring teams together to share your first ideas so
              you and your team can feedback, collaborate and align early on.
            </Text>
          </Box>
        </Flex>
      </BorderBox>

      <BorderBox maxWidth={900} m="0 auto">
        <Flex justifyContent="space-around" alignItems="center" mt={5}>
          <Box>
            <Text fontSize={6} fontWeight={600} mb={1} color="licorice">
              Organise your ideas effortlessly
            </Text>
            <Text fontSize={5}>
              Customise your Ideate projects with your own categories which
              house your uploads in different sections making it easier to
              navigate your thoughts.
            </Text>
          </Box>
          <Image
            alt="Organise your ideas effortlessly"
            maxWidth={400}
            src={phoneOne}
            srcSet={phoneOne}
            mr={4}
          />
        </Flex>
      </BorderBox>
      <BorderBox maxWidth={900} m="0 auto">
        <Flex justifyContent="space-around" alignItems="center" mt={5}>
          <Image
            alt="Visually communicate concepts"
            maxWidth={400}
            src={phoneOne}
            srcSet={phoneOne}
            mr={4}
          />
          <BorderBox maxWidth={500}>
            <Text fontSize={6} fontWeight={600} mb={1} color="licorice">
              Visually communicate concepts
            </Text>
            <Text fontSize={5}>
              Present your moodboards to the wider team and stakeholders with
              our play mode, section view and ability to showcase on any device
            </Text>
          </BorderBox>
        </Flex>
      </BorderBox>

      <Flex justifyContent="center" alignItems="center" my={120}>
        <BorderBox maxWidth={800} textAlign="center">
          <Text fontSize={8} fontWeight={600} color="licorice">
            Start as you mean to go on
          </Text>
          <Text fontSize={6}>
            Smoothly transition through the design process and go from ideation
            to design and prototyping in one platform.
          </Text>
          <Link to="/">
            <Button bg="marvel" color="white" size={4} mt={4}>
              Get started
            </Button>
          </Link>
        </BorderBox>
      </Flex>
    </Fragment>
  );
}
