import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PoseGroup } from 'react-pose';
import { CloseButton, EscKeyHandler, Image } from '@marvelapp/ui';

import { HoverItem, Overlay, Content, ButtonTransiton } from './styles';

class ImageItem extends Component {
  state = {
    isModalOpen: false,
  };

  onToggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  render() {
    const { src } = this.props;
    const { isModalOpen } = this.state;
    return (
      <Fragment>
        <HoverItem borderRadius={3} bg="snowDark">
          <Image
            borderRadius={3}
            display="block"
            onClick={this.onToggleModal}
            src={src}
          />
        </HoverItem>
        {isModalOpen && <EscKeyHandler onEscapeKey={this.onToggleModal} />}
        <PoseGroup>{isModalOpen && <Overlay key="overlay" />}</PoseGroup>
        <PoseGroup>
          {isModalOpen && (
            <Content key="content">
              <Image
                borderRadius={14}
                boxShadow="0 4px 16px rgba(0, 0, 0, 0.05)"
                display="block"
                maxHeight="90%"
                maxWidth="90%"
                objectFit="contain"
                onClick={this.onToggleModal}
                src={src}
              />
            </Content>
          )}
        </PoseGroup>
        <PoseGroup>
          {isModalOpen && (
            <ButtonTransiton key="button">
              <CloseButton onClick={this.onToggleModal} />
            </ButtonTransiton>
          )}
        </PoseGroup>
      </Fragment>
    );
  }
}

ImageItem.propTypes = {
  src: PropTypes.string,
};

export default ImageItem;
