import { createGlobalStyle } from 'styled-components';
import { color, fontFamily } from 'styled-system';

export const GlobalStyle = createGlobalStyle`
  @import url('https://s3.eu-west-2.amazonaws.com/marvelapp-styleguide/fonts/fonts.css');
  body {
    ${color};
    ${fontFamily};
    letter-spacing: -0.2px;
  }
  a, a:link, a:visited {
    text-decoration: none;
  } 
`;
